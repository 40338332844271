import * as constants from "./Constants";

class Helper {

    getQueryParameter = (name: string, defaultValue: string): string => {
    if (window.location.search) {
        const regEx = new RegExp("(\\?|&)" + name + "=(.+?)(&|#|$)", "i");
        const match = regEx.exec(window.location.search);
        if (match && match.length > 2) {
            return match[2];
        }
    }
  
    return defaultValue;
    }

    getLanguageParam = (): string => {
        return this.getQueryParameter(constants.QueryParameter.Language, constants.Language.Default);
    }
}

export default new Helper();