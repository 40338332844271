import { LocResources } from "./locResources";
import  Helper from "./Helper";

const locResources: LocResources = new LocResources(Helper.getLanguageParam());

function CheckoutErrorPage() {
  return (
    <html>
      <body>
        <div>
            <p>{locResources.getByName("CheckoutError")}</p>
        </div>
     </body>
    </html>
  );
}

export default CheckoutErrorPage;