import axios from "axios";

const MAX_RETRY_ATTEMPTS = 3;
const BASE_RETRY_DELAY_MS = 50;

const authInterceptor = axios.create();

authInterceptor.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status >= 500) {
      const config = error.config;
      config.retryCount = config.retryCount || 0;

      if (config.retryCount < MAX_RETRY_ATTEMPTS) {
        const delay = Math.pow(2, config.retryCount) * BASE_RETRY_DELAY_MS;
        config.retryCount += 1;
        return new Promise((resolve) =>
          setTimeout(() => resolve(axios.request(config)), delay)
        );
      }
    }
    return Promise.reject(error);
  }
);

export default authInterceptor;
