"use strict";

import { MiscUtil } from "./utils/miscUtils";

const enum services {
    commercialstores = "commercialstores",
    macmanage = "macmanage",
    bing = "bing",
    webblends = "webblends",
}

type Map<T> = { [key: string]: T };
export interface IPartnerOnboardingInfo {
    name: string;
    endpoint: string;
}

class partnerConfig {
    constructor() {
        this.updateEndpoints("prod");
    }

    private partnerEndpoints: Map<string> = {};

    private currentEnv = "prod";

    partnerOnboarding: Map<IPartnerOnboardingInfo> = {
        // TODO: need to confirm the partner names
        commercialstores: {
            name: "commercialstores",
            endpoint: "commercialstores-endpoint",
        },
        macmanage: {
            name: "macmanage",
            endpoint: "macmanage-endpoint",
        },
        bing: {
            name: "bing",
            endpoint: "bing-endpoint",
        },
        webblends: {
            name: "webblends",
            endpoint: "webblends-endpoint",
        },
    };

    private updateEndpoints = (env: string) => {
        const envSpecificServices: Map<string> = {};
        this.currentEnv = env;

        // TODO: need to confirm partner domains for whitelisting, and how to dealt with multiple
        // domains in one env?
        if (env === "int") {
            envSpecificServices[services.commercialstores] = "";
            envSpecificServices[services.macmanage] = "";
            envSpecificServices[services.bing] = "ads-int.microsoft.com,beta.ads-int.microsoft.com,sandbox.bingads.microsoft.com,sandbox.ads.microsoft.com";
            envSpecificServices[services.webblends] = "";
        } else if (env === "ppe") {
            envSpecificServices[services.commercialstores] = "localhost:3000";
            envSpecificServices[services.macmanage] = "localhost:3000";
            envSpecificServices[services.bing] = "ads-int.microsoft.com,beta.ads-int.microsoft.com,sandbox.bingads.microsoft.com,sandbox.ads.microsoft.com,localhost:3000";
            envSpecificServices[services.webblends] = "localhost:3000";
        } else if (env === "prod") {
            envSpecificServices[services.commercialstores] = "admin-sdf.microsoft.com,admin-ignite.microsoft.com,admin.microsoft.com";
            envSpecificServices[services.macmanage] = "admin-sdf.microsoft.com,admin-ignite.microsoft.com,admin.microsoft.com";
            envSpecificServices[services.bing] = "ads.microsoft.com,beta.ads.microsoft.com,bcp.ads.microsoft.com,tip.ads.microsoft.com";
            envSpecificServices[services.webblends] = "microsoft.com,cart.dev.microsoft.com:3000,samplehost.dev.microsoft.com:3002";
        }

        this.partnerEndpoints = {};
        this.partnerEndpoints[this.partnerOnboarding.commercialstores.endpoint] = envSpecificServices[services.commercialstores];
        this.partnerEndpoints[this.partnerOnboarding.macmanage.endpoint] = envSpecificServices[services.macmanage];
        this.partnerEndpoints[this.partnerOnboarding.bing.endpoint] = envSpecificServices[services.bing];
        this.partnerEndpoints[this.partnerOnboarding.webblends.endpoint] = envSpecificServices[services.webblends];
    }

    getPartnerEndpoint = (partner: string): string => {
        return this.partnerEndpoints[partner];
    }

    setEnv = (env: string): void => {
        this.updateEndpoints(env);
    }

    getEnv = (): string => {
        return this.currentEnv;
    }

    isWhitelisted = (env: string, partnerName: string): boolean => {
        this.setEnv(env)

        if (!this.partnerOnboarding[partnerName]) {
            return false;
        }

        const endpoints = this.getPartnerEndpoint(this.partnerOnboarding[partnerName].endpoint);
        if (MiscUtil.isNullOrUndefinedOrEmptyString(endpoints)) {
            return false
        }

        const origins = endpoints.split(",")
        const url = (window.location != window.parent.location)
            ? document.referrer
            : document.location.href;
        const actualOrigin = MiscUtil.getHostName(url);

        if (origins.indexOf(actualOrigin) != -1) {
            return true
        }

        return false
    }
}

export default new partnerConfig();
