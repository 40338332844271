export enum Language {
    Default = "en-US"
}

export enum DisplayId {
    TermsCheckout = "termsCheckout",
    PrivacyCheckoutStatement = "privacyCheckoutStatement"
}

export enum QueryParameter {
    Language = "language"
}

export enum EventNames {
    Navigate = "navigate",
    PageRendered = "pageRendered",
    Error = "error"
}