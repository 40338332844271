import * as Constants from "../constant/constants";

export class Timer {
  // default the timeout to 5 mins if no param is provided
  constructor(public counter = Constants.defaultTimeout) {}

  public startTimer() {
    return new Promise<boolean>(() => {
      setTimeout(() => {
        // TODO: what action to take when the challenge has timed out? do we simply log a warning or do a visual pop up to warm the user?
        console.warn("Your challenge has timed out");
      }, this.counter * 1000);
    });
  }
}
