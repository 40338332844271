import { LocStrings } from "./locStrings";

export interface ILocResources {
    getByName(resourceName: string, parameters?: string[]): string;
}

export class LocResources implements ILocResources {
    private languageResources : any = {};
    private defaultLanguageResources = LocStrings["en-us"];

    private LanguageMappings : any = {
        "nb-no": "no",
        "zh-chs": "zh-hans",
        "zh-cn": "zh-hans",
        "zh-sg": "zh-hans",
        "zh-cht": "zh-hant",
        "zh-hk": "zh-hant",
        "zh-mo": "zh-hant",
        "zh-tw": "zh-hant",
    };

    constructor(language: string) {
        language = (language || "en-us").toLowerCase();
        language = this.LanguageMappings[language] || language;
        while (language && !LocStrings[language]) {
            language = language.substr(0, language.lastIndexOf("-"));
        }

        this.languageResources = LocStrings[language] || LocStrings["en-us"];
    }

    getByName = (resourceName: string, parameters?: string[]): string => {
        const formatString = this.languageResources[resourceName] || this.defaultLanguageResources[resourceName];
        return formatString.replace(/\{(\d)\}/g, function(match : any, id : any) {
            return parameters??[id];
        });
    }
}