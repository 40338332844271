import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import GuestCheckout, { IGuestCheckoutProps } from "./GuestCheckout";
import reportWebVitals from "./reportWebVitals";
import Health from "./Health";
import CheckoutErrorPage from "./CheckoutErrorPage";
import { ChallengeProtocol } from "./challengeui/challengeProtocol";

const guestcheckoutProps = ReadGuestCheckoutProp(window.location.href);

if (new URL(window.location.href).pathname == "/health") {
  ReactDOM.render(
    <React.StrictMode>
      <Health />
    </React.StrictMode>,
    document.getElementById("root")
  );
} else if (new URL(window.location.href).pathname == "/error") {
  ReactDOM.render(
    <React.StrictMode>
      <CheckoutErrorPage />
    </React.StrictMode>,
    document.getElementById("root")
  );
} else if (new URL(window.location.href).pathname == "/challengeui") {
  // set the env to 'int' if guestcheckoutProps is null
  let env = "int"

  switch (new URL(window.location.href).hostname) {
    case "payint.microsoft.com":
      env = "int";
    break;
    case "payppe.microsoft.com":
      env = "ppe";
    break;
    case "pay.microsoft.com":
      env = "prod";
    break;
  }

  ReactDOM.render(
    <React.StrictMode>
      <ChallengeProtocol env={env}/>
    </React.StrictMode>,
    document.getElementById("root")
  );
} else if (guestcheckoutProps == null) {
  ReactDOM.render(
    <React.StrictMode>
      <div>404 Not Found :(</div>
    </React.StrictMode>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <GuestCheckout
        checkoutId={guestcheckoutProps.checkoutId}
        country={guestcheckoutProps.country}
        env={guestcheckoutProps.env}
        language={guestcheckoutProps.language}
        paymentProviderId={guestcheckoutProps.paymentProviderId}
        platformType={guestcheckoutProps.platformType}
        redirectUrl={guestcheckoutProps.redirectUrl}
        testScenarios={guestcheckoutProps.testScenarios}
      />
    </React.StrictMode>,
    document.getElementById("root")
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function ReadGuestCheckoutProp(href: string): IGuestCheckoutProps | null {
  // href example:
  // http://localhost:3000/payment-providers/stripe/checkout/123/start?redirectUrl=teams.microsoft.com&testScenarios=px.3pp.stripe.guestcheckout.success,px.sellermarket.stripe.us&platformType=msteams

  const parts = href.split("/");
  console.log(parts.length);
  console.log(parts);

  if (parts.length != 8) {
    return null;
  }

  const queryParams = new URL(href).searchParams;
  const checkoutId = href.split("/")[6];
  const paymentProviderId = href.split("/")[4];
  const redirectUrl = queryParams.get("redirectUrl") ?? "";
  const testScenarios = queryParams.get("testScenarios") ?? "";
  const platformType = queryParams.get("platformType") ?? "msteams";
  const language = queryParams.get("language") ?? "en-us";
  const country = queryParams.get("country") ?? "us";

  let env = "int";

  switch (new URL(href).hostname) {
    case "payint.microsoft.com":
      env = "int";
      break;
    case "payppe.microsoft.com":
      env = "ppe";
      break;
    case "pay.microsoft.com":
      env = "prod";
      break;
  }

  return {
    checkoutId: checkoutId,
    country: country,
    env: env,
    language: language,
    paymentProviderId: paymentProviderId,
    platformType: platformType,
    redirectUrl: redirectUrl,
    testScenarios: testScenarios,
  };
}
