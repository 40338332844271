import { Environment, HandleCheckout, IElementFactory, pidl } from "@cspayments/pidl-react";
import { FluentUIElementFactory } from "@cspayments/pidl-fluent-ui";
import './GuestCheckout.css';
import { useState } from "react";
import { EventHub } from "./eventHub";
import * as constants from "./Constants";

export interface IGuestCheckoutProps {
  checkoutId: string,
  country: string,
  env: string,
  language: string,
  paymentProviderId: string,
  platformType: string,
  testScenarios: string,
  redirectUrl: string,
} 

export declare type PidlEvent = (name: string, parameters: any) => void;

function GuestCheckout(props: IGuestCheckoutProps) {
  const telemetryContext = {
    userContext: {userId: "me"},
    partnerContext: {partnerId: "msteams", env: props.env}
  };

  const event =  new EventHub(telemetryContext);

  const startTime: number = Date.now();

  event.trackEvent("GuestCheckout_Request_Received", {});
  
  const [canShowOverlay, SetCanShowOverlay] = useState(false);

  const onBusy = (isBusy: boolean) => {
    SetCanShowOverlay(isBusy);
  }

  const checkoutPramas : pidl.ICheckoutParams = {
    anonymousUser: true,
    checkoutId: props.checkoutId,
    market: props.country,
    language: props.language,
    partner: props.platformType,
    paymentProviderId: props.paymentProviderId,
    redirectUrl: props.redirectUrl, 
  };

  const returnOptions : pidl.IDisplayControlReturnOptions = {
    urls: {
      success: props.redirectUrl, 
      failure: props.redirectUrl, 
      gohome:  props.redirectUrl,
    }
  };

  const additionalHeaders: pidl.IAdditionalHeaders = {
    "x-ms-test": `{"scenarios": "${props.testScenarios}", "contact": "pay.microsoft.com" }`
  };

  const userContext : pidl.IDisplayControlUserContext = {
    additionalHeaders: props.testScenarios ? additionalHeaders : {}
  };
  
  const displayOptions : pidl.IDisplayControlDisplayOptions = {
    containerId: "pidl-react-container",
    busyBlockShowHideHandler: onBusy,
  };

  const env: Environment =  props.env as Environment ?? "int";

  const officeFabric: IElementFactory =  new FluentUIElementFactory()

  const onPidlEvent: PidlEvent = (eventName: string, eventParameters: any) => {
    if (eventName === constants.EventNames.Navigate) {
      window.location.assign(eventParameters.url);
      return true;
    }
    else if (eventName == constants.EventNames.PageRendered) {
      event.trackEvent("GuestCheckout_Form_Loaded", { timeTaken: Date.now() - startTime });
      return true;
    }
    else if (eventName == constants.EventNames.Error) {
      event.trackEvent("GuestCheckout_Failed", {});
      return true;
    }
    return false;
  }

  const telemetryOptions : pidl.IDisplayControlTelemetryOptions = {
    disableLogging: true
  }

  return (
    <div className = "App">
      <HandleCheckout 
        userContext = {userContext}
        checkoutParams = {checkoutPramas} 
        returnOptions = {returnOptions}
        displayOptions = {displayOptions}
        env = {env}
        elementFactory = {officeFabric} 
        onEvent = {onPidlEvent}
        telemetryOptions= {telemetryOptions}
        />
      {canShowOverlay ? <div id="overlay" /> : null}
    </div>
  );  
}

export default GuestCheckout;
