import * as React from "react";
import { Timer } from "../utils/timer";

export interface IAdaptor {
  onInit?: () => JSX.Element;
  timeout?: number;
  // optional event handler: they are nice to have and might be used in the future adaptor services
  onReady?: () => void;
  onShown?: () => void;
  onShow?: () => void;
  onSuppress?: () => void;
  onReset?: () => void;
  onHide?: () => void;
  onError?: (data: any) => void;
  // required event handler
  onCompleted: (data: any) => void;
  onFailed: (data: any) => void;
}

export interface EventDataType {
  sessionId: string;
  token: string;
  encryptedBlob: string;
}

/**
what are all the adaptors have in common? even though we only know of Arkose for now?
what can I write here to make it flexible enough to cover future adaptor services?
**/
export class BaseAdaptor<P> extends React.Component<P & IAdaptor> {
  private timer: Timer;

  constructor(props: P & IAdaptor) {
    super(props);

    // TODO: should we add app insight telemetry for all adaptors?

    // add timeout functionality for all adaptors
    this.timer = new Timer(this.props.timeout);
    this.timer.startTimer();
  }

  render() {
    return <div>{this.props.onInit && this.props.onInit()}</div>;
  }
}
